import React, { forwardRef } from 'react'
import { navigate } from 'gatsby'
import { Row, Col, Container } from '@kogk/common'
import styles from './SearchInputSection.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import useQueryParams from '@root/src/hooks/useQueryParams'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'

export default forwardRef(
  ({ onChange = () => {}, placeholder, className }, ref) => {
    const { q: qraw = '' } = useQueryParams()
    const q = qraw.trim()
    return (
      <Container fluid className={cn(className, 'py-3')}>
        <Row className='justify-content-center'>
          <Col col={{ md: 6 }} className=''>
            <div className={cn(styles.inputWrapper, 'form-input d-flex')}>
              <input
                ref={ref}
                name='search input'
                className='w-100'
                type='text'
                defaultValue={q}
                placeholder={placeholder}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    navigate(`/vorur?q=${encodeURIComponent(e.target.value)}`)
                  } else {
                    onChange(e)
                  }
                }}
              />
              <FontAwesomeIcon icon={faSearch} />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
)
